.ant-card .ant-card-head-title {
  padding: 16px 0;
}

.ant-tabs-tab .anticon {
  margin-right: 12px;
}

.ant-layout {
  background-color: transparent;
}

.ant-page-header {
  max-width: 1424px;
  width: 100%;
  margin: auto;
}

.app-container {
  width: 100%;
  min-height: 100vh;
}

.app-layout {
  max-width: 1424px;
  width: 100%;
  margin: auto;
}

.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
}

.app-content {
  padding: 3rem 16px;
}

.app-footer {
  padding: 0 !important;
  background: none;
}

footer .ant-typography {
  margin-bottom: 0;
}

.app-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 11;
}

.ant-typography a {
  color: var(--primary);
}

.ant-btn-primary {
  background-color: var(--primary);
}

.ant-btn-primary:active {
  background-color: var(--primary);
}

.ant-modal .ant-modal-content {
  padding: 24px !important;
}

.ant-modal .ant-modal-header {
  padding: 8px 0;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
}

.ant-modal .ant-modal-footer {
  margin-top: 22px;
}
