html {
  box-sizing: border-box;
  background-color: white;
}
*,
*:after,
*:before {
  box-sizing: inherit;
}
* {
  margin: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  max-width: 100%;
}
