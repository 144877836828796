#root {
  display: flex;
  justify-content: center;
}

body {
  font-size: 14px;
  line-height: 1.5;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

.fullWidth {
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.muted {
  color: var(--gray);
}

.cap {
  text-transform: capitalize;
}

.cap-all > * {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}
